.aboutsection {
  display: flex;
  align-items: center;
  overflow-x: visible;
}
.aboutsection.bio-active,
.aboutsection .aboutsection-about,
.aboutsection .aboutsection-bio,
.aboutsection.bio-active .aboutsection-about,
.aboutsection.bio-active .aboutsection-about {
  transition: 1s;
}

.aboutsection.bio-active .about {
  transform: translate(-100%);
}
.aboutsection.bio-active .bio {
  transform: translate(-100%);
}
.aboutsection.bio-active .about {
  transform: translate(-100%);
  width: 100vw;
}
.aboutsection.bio-active .bio {
  transform: translate(-100%);
  width: 100vw;
}
