.about {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
  background-color: #e7fae7;
}
.about .about-content {
  width: 90vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* https://images.pexels.com/photos/7688374/pexels-photo-7688374.jpeg?auto=compress&cs=tinysrgb&w=800 */
.about .about-left {
  width: 45%;
  height: 60vh;
  background-image: url(../../assets/img/about.svg);
  background-position: center;
  background-size: contain;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}
.about .about-right {
  width: 50%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.about .about-right button {
  border: none;
  color: #e7fae7;
  background-color: #3f3d56;
  padding: 10px;
  transition: 0.4s;
  margin: 2px;
}
.about .about-right button:hover {
  color: #fff;
  padding: 12px;
  margin: 0;

  background-color: #4bb949;
}

@media (max-width: 832px) {
  .about .about-content {
    flex-direction: column-reverse;
  }
  .about .about-left {
    width: 80vw;
    height: 40vw;
    margin-bottom: 5vh;
  }
  .about .about-right {
    width: 90vw;
    height: auto;
  }
}
