.about-boxes {
  background: url("https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2")
    center top no-repeat fixed;
  background-size: cover;
  padding: 60px 0 30px 0;
  position: relative;
}

.about-boxes::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.722);
  z-index: 9;
}
.about-boxes a {
  color: #4bb949;
  text-decoration: none;
}

.about-boxes a:hover {
  color: #50b94f;
  text-decoration: none;
}

.about-boxes .container,
.about-boxes .container-fluid {
  position: relative;
  z-index: 10;
}

.about-boxes .card {
  /* border-radius: 3px; */
  border: 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.about-boxes .card-icon {
  text-align: center;
  margin-top: -32px;
}

.about-boxes .card-icon .icon {
  font-size: 32px;
  color: #fff;
  width: 64px;
  height: 64px;
  padding-top: 5px;
  text-align: center;
  background-color: #4bb949;
  /* border-radius: 4px; */
  text-align: center;
  border: 4px solid #fff;
  transition: 0.4s;
  display: inline-block;
}

.about-boxes .card-body {
  padding-top: 12px;
}

.about-boxes .card-title {
  font-weight: 700;
  text-align: center;
}

.about-boxes .card-title a {
  color: #15222b;
}

.about-boxes .card-title a:hover {
  color: #4bb949;
}

.about-boxes .card-text {
  color: #5e5e5e;
}

.about-boxes .card:hover .card-icon .icon {
  background: #fff;
  color: #4bb949;
}

@media (max-width: 1024px) {
  .about-boxes {
    background-attachment: scroll;
  }
}
