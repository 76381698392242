.bio {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
  background-color: #22273e;
  color: #fff;
}
.bio .bio-content {
  width: 90vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* https://images.pexels.com/photos/7688374/pexels-photo-7688374.jpeg?auto=compress&cs=tinysrgb&w=800 */
.bio .bio-left {
  width: 45%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
}
.bio .bio-left .bio-image {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/leo.png);
  background-position: center;
  background-size: contain;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}
.bio .bio-left button {
  border: none;
  color: #e7fae7;
  background-color: #4bb949;
  padding: 10px;
  transition: 0.4s;
  margin-top: 10vh;
}
.bio .bio-left button:hover {
  color: #fff;
  padding: 12px;
  margin-top: 6vh;

  background-color: #3f3d56;
}
.bio .bio-right {
  width: 50%;
  height: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.bio .bio-right .section-header h2 {
  color: #4bb949;
}

@media (max-width: 832px) {
  .bio .bio-content {
    flex-direction: column-reverse;
  }
  .bio .bio-left {
    width: 80vw;
    height: 70vw;
    margin-bottom: 5vh;
    object-fit: contain;
  }
  .bio .bio-right {
    width: 90vw;
    height: auto;
  }
}
