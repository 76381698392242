.whyus {
  background-color: #e7fae7;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  flex-direction: column;
  padding-bottom: 10vh;
  padding-top: 10vh;
}
.whyus .whyus-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60vh;
}
.whyus .whyus-container .whyus-card {
  width: 18vw;
  height: 50vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  margin-left: 1vw;
  margin-right: 1vw;
  align-items: center;
  text-align: center;
  transition: 0.4s;
}
.whyus .whyus-container .whyus-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.592);
  border-radius: 20px;
}

.whyus .whyus-container .whyus-card h3 {
  /* color: #4bb949; */
  text-shadow: 0px 0px 1px #d3d3d3;
}
.whyus .whyus-container .whyus-card img {
  width: 100px;
}

@media (max-width: 992px) {
  .whyus {
    height: auto;
  }
  .whyus .whyus-container {
    height: auto;
    flex-direction: column;
  }
  .whyus .whyus-container .whyus-card {
    width: 90vw;
    margin-bottom: 1vh;
  }
}
