.services {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5vh;
}
.services .services-container {
  width: 80vw;
  flex-direction: column;
}

.services .services-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5vh;
  background-color: aliceblue;
  width: 100%;
}
.services .services-tabs .services-tabs-option {
  padding: 10px;
  /* border-radius: 5px; */
  cursor: pointer;
  transition: 0.2s;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #3f386a;
  text-transform: uppercase;
}
.services .services-tabs .services-tabs-option.active {
  border: solid 1.5px #4bb949;
  border-bottom: none;
  transition: 0.2s;
  color: #4bb949;
}
.services .services-tabs .services-tabs-option:hover {
  border: solid 1.5px #4bb949;
  border-bottom: none;
}
.services .services-content {
  display: flex;
  width: inherit;
  justify-content: space-between;
  min-height: 20vh;
}
.services .services-content .services-left {
  width: 40vw;
  position: relative;
}
.services .services-container .service-more-info {
  background-color: rgba(0, 0, 0, 0.763);
  height: auto;
  width: 40vw;
  padding: 5px;
  color: #fff;
  position: absolute;
  left: 2.5vw;
}
.services .services-content .services-left ul {
  overflow-y: scroll;
  max-height: 53vh;
}
.services .services-content .services-left ul li {
  list-style: none;
  margin-bottom: 10px;
  cursor: pointer;
  border: solid 1px #d3d3d3;
  border-radius: 5px;
  padding: 10px;
  transition: 0.4s;
  font-size: 14px;
  text-decoration: none;
}
.services .services-content .services-left ul li:hover {
  box-shadow: 0px 0px 10px #d3d3d3;
}
.services .services-content .services-left .pension {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  min-height: 40vh;
}
.services .services-content .services-right {
  width: 35vw;
  height: inherit;
  min-height: 35vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.services .section-btn {
  background: #4bb949;
  border: 0;
  padding: 13px 50px;
  color: #fff;
  transition: 0.4s;
  border-radius: 0;
  margin-top: 5vh;
  text-decoration: none !important;
}

.services .section-btn:hover {
  background: rgba(5, 187, 5, 0.85);
}

@media (max-width: 832px) {
  .services .services-content {
    flex-direction: column;
    align-items: center;
  }
  .services .services-container {
    width: 90vw;
  }
  .services .services-content .services-left {
    width: 80vw;
  }
  .services .services-content .services-right {
    display: none;
  }
}
