.services {
  height: auto;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
}
.services .services-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
}

.services .services-container .services .service {
  cursor: pointer;
  transition: 0.8s;
  background-size: cover;
  background-position: center;
  position: relative;
}
.services .services-container .services .service:hover {
  transform: scale(1.03);
  border-radius: 20px;
  overflow: hidden;
}
.services .services-container .services .service::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.882);
}
.services .service .service-container {
  position: relative;
  z-index: 12;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  text-shadow: 0px 0px 5px black;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: inherit;
  cursor: pointer;
}
.services .service .service-container.hovered {
  font-size: 16px;
  font-weight: 400;
  color: "red";
  display: flex;
  align-items: center;
  padding: 10px;
  height: 100%;
  background-color: black;
}

.services .service:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.869);
}

.services .services-container .services-left {
  width: 74%;
  height: auto;
}
.services .services-left .service-left-top {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.services .services-left .service-left-top .service-left-top-left {
  width: 30%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.services .services-left .service-left-top-left > div:nth-child(1) {
  width: 100%;
  height: 68%;
  background-color: red;
  background-image: url(https://images.pexels.com/photos/6169668/pexels-photo-6169668.jpeg?auto=compress&cs=tinysrgb&w=800);
}
.services .services-left .service-left-top-left > div:nth-child(2) {
  width: 100%;
  height: 30%;
  background-color: blue;
  background-size: cover;
  background-position: center;
  background-image: url(https://images.pexels.com/photos/13073688/pexels-photo-13073688.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load);
}
.services
  .services-left
  .service-left-top-left
  > div:nth-child(2)
  .service-container:hover:hover {
  font-size: 13px;
  line-height: 25px;
}

.services .service-left-top-right {
  height: inherit;
  width: 68.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.services .service-left-top-right .service-left-top-right-top {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: space-between;
}
.services
  .service-left-top-right
  .service-left-top-right-top
  > div:nth-child(1) {
  width: 49%;
  height: 100%;
  background-color: aquamarine;
  background-size: cover;
  background-image: url(https://images.pexels.com/photos/730547/pexels-photo-730547.jpeg?auto=compress&cs=tinysrgb&w=800);
}
.services
  .service-left-top-right
  .service-left-top-right-top
  > div:nth-child(2) {
  width: 49%;
  height: 100%;
  background-color: violet;
  background-size: cover;
  background-image: url(https://images.pexels.com/photos/139398/thermometer-headache-pain-pills-139398.jpeg?auto=compress&cs=tinysrgb&w=800);
}
.services
  .service-left-top-right
  .service-left-top-right-top
  > div:nth-child(2)
  .service-container:hover {
  font-size: 14px;
  line-height: 25px;
}
.services .service-left-top-right .service-left-top-right-bottom {
  width: 100%;
  height: 58%;
}
.services .service-left-top-right .service-left-top-right-bottom > .service {
  width: 100%;
  height: 100%;
  background-color: gray;
  background-image: url(https://images.pexels.com/photos/116675/pexels-photo-116675.jpeg?auto=compress&cs=tinysrgb&w=800);
}
.services .services-left .service-left-bottom {
  height: 30vh;
  width: 100%;
  margin-top: 2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.services .services-left .service-left-bottom > div:nth-child(1) {
  width: 58%;
  height: 100%;
  background-color: brown;
  background-image: url(https://images.pexels.com/photos/7416178/pexels-photo-7416178.png?auto=compress&cs=tinysrgb&w=800);
  background-position: top;
}
.services .services-left .service-left-bottom > div:nth-child(2) {
  width: 41%;
  height: 100%;
  background-size: cover;
  background-color: crimson;
  background-image: url(https://images.pexels.com/photos/7550283/pexels-photo-7550283.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load);
}
.services
  .services-left
  .service-left-bottom
  > div:nth-child(2)
  .service-container:hover {
  font-size: 14px !important;
  line-height: 25px;
}
.services .services-container .services-right {
  width: 25%;
}

.services .services-container .services-right > div:nth-child(1) {
  background-color: yellow;
  height: 50vh;
  width: 100%;
  margin-bottom: 2vh;
  background-image: url(https://images.pexels.com/photos/3932930/pexels-photo-3932930.jpeg?auto=compress&cs=tinysrgb&w=800);
}
.services .services-container .services-right > div:nth-child(2) {
  background-color: green;
  height: 50vh;
  width: 100%;
  background-image: url(https://images.pexels.com/photos/57461/fire-burn-hell-warm-57461.jpeg?auto=compress&cs=tinysrgb&w=800);
}

@media (max-width: 832px) {
  .services .services-container {
    flex-direction: column;
    width: 98vw;
  }
  .services .services-container .services-right {
    width: 100%;
  }
  .services .services-container .services-left {
    width: 100%;
  }
  .services .services-container .services-right > div:nth-child(1) {
    margin-bottom: 1vh;
    margin-top: 1vh;
  }
  .services .services-container .services-right > div:nth-child(2) {
  }
}

@media (max-width: 576px) {
  .services .service-left-top {
    flex-direction: column-reverse;
  }
  .services .services-left .service-left-top .service-left-top-left {
    width: 100%;
    margin-top: 1vh;
  }
  .services .service-left-top .service-left-top-right {
    width: 100%;
  }
  .services .services-left .service-left-top {
    height: auto;
  }
  .services .service-left-top-right .service-left-top-right-bottom {
    width: 100%;
    height: 50vh;
    margin-top: 1vh;
  }
  .services .services-left .service-left-top-left > div:nth-child(1) {
    height: 40vh;
  }
  .services .services-left .service-left-top-left > div:nth-child(2) {
    height: 30vh;
    margin-top: 1vh;
  }
  .services
    .service-left-top-right
    .service-left-top-right-top
    > div:nth-child(1) {
    height: 20vh;
  }
  .services
    .service-left-top-right
    .service-left-top-right-top
    > div:nth-child(2) {
    height: 20vh;
  }
}
