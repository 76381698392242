.hero {
  height: 100vh;
  width: 100vw;
  position: relative;
}

/* 
MAIN BANNER
 */
.main-banner {
  position: relative;
}
#bg-video {
  min-width: 100%;
  min-height: 100vh;
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  background-color: rgba(35, 45, 57, 0.8);
  background-attachment: fixed;
  top: 0;
  left: 0;
  bottom: 7px;
  width: 100%;
}
#bg-video::-webkit-media-controls {
  display: none !important;
}
.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.196);
}

.hero-content {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 12;
  position: relative;
}

.hero-content h1 {
  color: #fff;
  font-size: 82px;
  margin-top: 10vh;
  /* width: 80%; */
  font-weight: 700;
  text-shadow: 1px 1px black;
}

.hero-content .hero-center {
  height: 100vh;
  width: 80vw;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.hero-content .hero-subtitle {
  color: #fff;
  width: 80%;
  text-shadow: 1px 1px black;
}

.hero-footer {
  width: 100%;
  height: 35vh;
  display: flex;
}
.hero-footer .hero-white-bg {
  background-color: #ffffffd6;
  height: inherit;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-footer .hero-white-bg .hero-white-bg-content {
  width: 70%;
  height: 90%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.hero-footer .hero-white-bg .hero-white-bg-content h2 {
  color: #4db946;
  font-weight: 700;
}
.hero-footer .hero-white-bg .hero-white-bg-content li {
  margin-bottom: 3%;
}
.hero-content .hero-footer .lema-logo {
  width: 150px;
}
.hero-footer .hero-no-div {
  height: inherit;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}
.hero-footer .hero-no-div h2 {
  font-size: 88px;
  text-shadow: 1px 1px black;
}

@media (max-width: 992px) {
  .hero-footer {
    display: none;
  }
}
@media (max-width: 568px) {
  .hero-content h1 {
    width: 95%;
    font-size: 48px;
  }
  .hero-content .hero-center {
    height: auto;
    margin-top: 10vh;
  }
  .hero-content .hero-subtitle {
    color: #fff;
    width: 95%;
  }
}
