.header {
  height: 10vh;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 1313;
}
.header .logo {
  width: 200px;
}
.header nav {
  width: 70vw;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header nav .nav-option {
  width: 25%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  cursor: pointer;
  color: #3f386a;
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
}
.header nav .nav-option.active {
  background-color: #4bb949;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}
.header .nav-mobile {
  position: absolute;
  top: 10vh;
  height: 90vh;
  right: 0;
  background-color: rgba(0, 0, 0, 0.772);
  flex-direction: column;
  width: 80vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
}
.header .nav-mobile .nav-option {
  width: 80%;
  color: #e3e3e3;
}
