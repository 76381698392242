.clients {
  margin-top: 5vh;
  margin-bottom: 10vh;
}
.clients .section-header {
  margin-bottom: -5vh;
}
.clients .clients-wrap {
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
}

.clients .client-logo {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  overflow: hidden;
  background: #fff;
  height: 100px;
}

.clients .client-logo .img-fluid {
  transition: all 0.3s ease-in-out;
  height: 106px;
  /* filter: grayscale(100%); */
}
/* .clients .client-logo .img-fluid.mobile {
  filter: grayscale(0%);
} */

.clients .client-logo:hover img {
  filter: none;
  transform: scale(1.1);
}
